import React from "react"
import { Link } from "react-router-dom"

import { APPLICATION } from "../../global/ConstantsRegistry"
import twitterIcon from "../../assets/images/icons8-twitterx.svg"

export const Footer = () => {

    return (
        <React.Fragment>
            <div className="w-full px-3 py-5 bg-orange-100">
                <div className="mx-auto container md:text-xl text-base md:max-w-4xl lg:max-w-4xl xl:max-w-5xl pb-5">
                    <div className="max-w-screen-lg text-base text-gray-800 sm:grid md:grid-cols-3 sm:grid-cols-2 mx-auto">
                        <div className="md:py-5 py-3">
                            <h1 className="pb-4 text-3xl font-medium tracking-tight text-orange-600 sm:text-3xl">
                                {APPLICATION.NAME}

                                <span className="text-sm block text-gray-800 py-2 font-normal tracking-wide">
                                    Elevated Acts of Appreciation.
                                </span>

                                <div className="block py-2">
                                    <Link className="rounded-md text-base py-2 font-normal animate-bounce transition duration-200 tracking-wide px-4 text-white bg-orange-600" to="/auth/sign-in">
                                        Sign In <span className=" text-xs p-1"></span>
                                    </Link>
                                </div>
                            </h1>
                        </div>

                        <div className="md:py-5 py-0">
                            <div className="text-sm uppercase text-orange-600 font-semibold">Resources</div>
                            <Link className="my-3 block" to="/faqs">
                                FAQs <span className="text-blue-600 text-xs p-1"></span>
                            </Link>

                            <a className="my-3 block" href="/#">
                                About Us <span className="text-blue-600 text-xs p-1"></span>
                            </a>

                            <Link className="my-3 block" to="/terms">
                                Terms & Conditions <span className="text-blue-600 text-xs p-1"></span>
                            </Link>

                            <Link className="my-3 block" to="/privacy-policy">
                                Privacy Policy <span className="text-blue-600 text-xs p-1"></span>
                            </Link>
                        </div>

                        <div className="md:py-5 py-4">
                            <div className="text-sm uppercase text-orange-600 font-semibold">Contact us</div>
                            <a className="my-3 block" href="/#">info@bigfan.co.ke
                                <span className="text-blue-600 text-xs p-1"></span>
                            </a>
                            <a className="my-3 block" href="/#">support@bigfan.co.ke
                                <span className="text-blue-600 text-xs p-1"></span>
                            </a>
                        </div>
                    </div>

                    <div className="pt-2">
                        <span className="text-sm text-center block text-gray-800 py-2 font-normal tracking-wide">
                            Big Fan © {new Date().getFullYear()} All Rights Reserved.
                        </span>

                        <div className="flex flex-col items-center max-w-screen-lg m-auto pt-5 pb-5 px-3 text-sm text-gray-800 border-t border-orange-300">
                            <div className="flex flex-row md:flex-row-reverse mt-2">
                                {[
                                    {
                                        href: "https://x.com/bigfankenya?t=vwA5l8AbgojQ6TghJJSd_g&s=09",
                                        name: "fa-twitter",
                                        id: "Twitter",
                                        type: 'svg'
                                    },
                                    {
                                        href: "https://www.facebook.com/profile.php?id=61567889602997&mibextid=ZbWKwL",
                                        name: "fa-facebook",
                                        id: "Facebook",
                                        type: 'icon'
                                    },
                                    {
                                        href: "https://www.instagram.com/bigfanke_?igsh=bmg3a3l1amsyZG1h",
                                        name: "fa-instagram",
                                        id: "Instagram",
                                        type: 'icon'
                                    },
                                ].map((icon, index) => (
                                    <Link key={index} to={icon.href} target="_blank" className="w-6 mx-1">
                                        {
                                            icon.type === 'svg' ? (
                                                <img src={twitterIcon} className="" />
                                            ) : (
                                                <span className={`fa-brands fa-xl ${icon.name}`}></span>
                                            )
                                        }
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}