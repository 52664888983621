import { Helmet } from "react-helmet"
import Compressor from 'compressorjs'
import { toast } from "react-toastify"
import React, { useEffect, useState } from "react"

import { ERR_404 } from "../errors/ERR_404"
import { ERR_500 } from "../errors/ERR_500"
import { ACCOUNT } from "../../api/API_Registry"
import HttpServices from "../../services/HttpServices"
import { Loading } from "../../components/modules/Loading"
import { API_RouteReplace, classNames } from "../../lib/modules/HelperFunctions"
import twitterIcon from "../../assets/images/icons8-twitterx.svg"
import { G_onInputChangeHandler, G_onInputBlurHandler } from "../../components/lib/InputHandlers"
import { genericRoutes } from "../../routes/routes"
import { Link } from "react-router-dom"

export const CreatorHub = () => {
    const [state, setstate] = useState({
        wordCount: 0,
        posting: false,
        httpStatus: 200,
        uploading: false,
        status: 'pending',
        data: {
            entity: null,
            links: null,
        },
        input: {
            page_name: '',
            creating: '',
            profile: '',
            coverPhoto: null,
            coverFile: null,
            bio: '',
            youtube: '',
            instagram: '',
            tiktok: '',
            facebook: '',
            twitter: '',
            spotify: '',
            apple_music: '',
        },
        errors: {
            page_name: '',
            creating: '',
            profile: '',
            coverPhoto: '',
            bio: '',
            youtube: '',
            instagram: '',
            tiktok: '',
            facebook: '',
            twitter: '',
            spotify: '',
            apple_music: '',
        }
    })

    const [isFocused, setIsFocused] = useState(false);
    let creatorsPageRoute: any = (
        genericRoutes.find(
            (routeName) => routeName.name === 'ENTITY_0_'
        )
    )?.path

    useEffect(() => {
        fetchCreatorSettings()
    }, [])

    const fetchCreatorSettings = async () => {
        let { data } = state
        let { input } = state
        let { status } = state
        let { wordCount } = state
        let { httpStatus } = state

        try {
            const creatorResp: any = await HttpServices.httpGet(ACCOUNT.CREATOR_HUB)
            httpStatus = creatorResp.status

            if (creatorResp.data.success) {
                status = 'fulfilled'
                const payload = creatorResp.data.payload

                data.links = payload.links
                data.entity = payload.entity

                input.bio = data.entity.bio
                input.page_name = data.entity.name
                input.creating = data.entity.creator
                input.profile = data.entity.profile_photo

                if (data.links) {
                    input.youtube = data.links.youtube
                    input.instagram = data.links.instagram
                    input.tiktok = data.links.tiktok
                    input.facebook = data.links.facebook
                    input.twitter = data.links.twitter
                    input.spotify = data.links.spotify
                    input.apple_music = data.links.apple_music
                }

                wordCount = data.entity.bio.length
            } else {
                status = 'rejected'
            }
        } catch (error) {
            httpStatus = 500
            status = 'rejected'
        }

        setstate({
            ...state, status, data, httpStatus, input, wordCount
        })
    }

    const onChangeHandler = (e: any) => {
        let { posting } = state

        if (!posting) {
            let output: any = G_onInputChangeHandler(e, state.posting)
            let { input } = state
            let { errors } = state
            let { wordCount } = state

            switch (e.target.name) {
                case 'bio':
                    const targetValue = e.target.value
                    wordCount = targetValue.length
                    output.value = e.target.value.slice(0, 499);
                    break;

                default:
                    break;
            }

            input[e.target.name] = output.value
            errors[e.target.name] = output.error

            setstate({
                ...state, input, errors, wordCount
            })
        }
    }

    const targetLength = (name: string) => {
        switch (name) {
            case 'bio':
                return {
                    min: 50,
                    max: 500
                };

            case 'creating':
                return {
                    min: 3,
                    max: 30
                };

            default:
                return {
                    min: 5,
                    max: 70
                };
        }
    }

    const onInputBlur = (e: any) => {
        let { input } = state
        let { posting } = state
        let { errors } = state

        if (!posting) {
            const target0 = targetLength(e.target.name)
            let output: any = G_onInputBlurHandler(e, state.posting, '', target0.min, target0.max)

            switch (e.target.name) {
                case 'bio':
                    setIsFocused(false)
                    output.error = output.error.replace('Bio', 'Your bio')
                    break

                case 'youtube':
                case 'instagram':
                case 'tiktok':
                case 'facebook':
                case 'twitter':
                case 'spofity':
                case 'apple_music':
                    const urlPattern = new RegExp(
                        '^(https?:\\/\\/)?' + // Optional protocol
                        '((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|' + // Domain name
                        'localhost|' + // OR localhost
                        '\\d{1,3}(\\.\\d{1,3}){3})' + // OR IPv4 address
                        '(\\:\\d+)?' + // Optional port
                        '(\\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?$' + // Optional path
                        '(\\?[;&a-zA-Z0-9%_+=]*)?' + // Optional query string
                        '(\\#[-a-zA-Z0-9_]*)?$', // Optional fragment
                        'i'
                      );
                    let validURL = urlPattern.test(output.value)

                    if (!validURL) {
                        output.error = 'Please enter a valid URL'
                    }
                    break

                default:
                    break;
            }

            if (e.target.required) {
                errors[e.target.name] = output.error
            } else if (!e.target.required && e.target.value.length > 0) {
                errors[e.target.name] = output.error
            } else {
                errors[e.target.name] = ''
            }

            input[e.target.name] = output.value

            setstate({
                ...state, input, errors
            })
        }
    }

    const onFileChangeHandler = async (e: any) => {
        let { posting } = state

        if (!posting) {
            let { input } = state
            let { errors } = state

            let fileSize = (e.target.files[0].size / 1024) / 1024
            let fileType = e.target.files[0].type
            let file = e.target.files[0]
            errors[e.target.name] = ''

            if (fileType !== 'image/png' && fileType !== 'image/jpg' && fileType !== 'image/jpeg') {
                errors[e.target.name] = 'Allowed file types are png, jpg and jpeg files'
                input.coverPhoto = null
                input.coverFile = null

                setstate({
                    ...state, input, errors
                })

                return
            } else if (fileSize > 2) {
                errors[e.target.name] = 'Maximum file upload size is 2 MB'
                input.coverPhoto = null
                input.coverFile = null

                setstate({
                    ...state, input, errors
                })

                return
            } else {
                try {
                    const { width, height }: any = await validateImageDimensions(file);

                    if (width < 1600 || height < 400) {
                        errors[e.target.name] = 'Image dimensions should not be less than 1600x400 pixels';
                        input.coverPhoto = null;
                        input.coverFile = null;

                        setstate({
                            ...state, input, errors
                        })

                        return;
                    }
                } catch (error) {
                    toast.error('Cover image upload failed, please try again later', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })

                    return
                }
            }

            const uncompressedImage = e.target.files[0];
            new Compressor(uncompressedImage, {
                quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                success: (compressedResult) => {
                    // compressedResult has the compressed file.
                    // Use the compressed file to upload the images to your server.
                    input.coverPhoto = compressedResult
                },
            });

            input.coverFile = e.target.files[0].name
            errors[e.target.name] = ''

            setstate({
                ...state, input, errors, uploading: true
            })

            uploadCoverPhoto()
        }
    }

    const validateImageDimensions = (file: any) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const { width, height } = img;
                resolve({ width, height });

                URL.revokeObjectURL(img.src);
            };

            img.onerror = () => reject('Invalid image file.');
            img.src = URL.createObjectURL(file);
        });
    };

    const uploadCoverPhoto = async () => {
        let { data } = state
        let { input } = state
        let { uploading } = state

        if (!uploading) {
            try {
                let formData = new FormData()
                formData.append("coverPhoto", input.coverPhoto)

                const uploadResp: any = await HttpServices.httpPostMultipartForm(ACCOUNT.CREATOR_COVER_PHOTO, formData)

                if (uploadResp.data.success) {
                    let payload = uploadResp.data.payload
                    data.entity.cover_photo = payload.cover

                    input.coverPhoto = null
                    input.coverFile = null
                } else {
                    toast.error('Cover image upload failed, please try again later', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

                uploading = false
            } catch (error) {
                uploading = false

                toast.error('Cover image upload failed, please try again later', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            setstate({
                ...state, uploading, data
            })
        }
    }

    const onFormSubmitHandler = (e: any) => {
        e.preventDefault()
        let { posting } = state

        if (!posting) {
            let validity = true
            let { input } = state
            let { errors } = state

            const inputArray = Object.keys(input)
            const errorArray = Object.keys(errors)

            inputArray.forEach(async (inputObject) => {
                let required = false
                let inputValue = input[inputObject] === null ? '' : input[inputObject]

                switch (input[inputObject]) {
                    case 'page_name':
                        required = true
                        break;

                    default:
                        break;
                }

                const makeShiftE = {
                    target: {
                        required: required,
                        name: inputObject,
                        value: String(inputValue).trim(),
                    }
                }

                switch (makeShiftE.target.name) {
                    case 'coverFile':
                    case 'coverPhoto':
                        break;

                    default:
                        onInputBlur(makeShiftE)
                        break;
                }

            })

            errorArray.forEach((errorPnk) => {
                if (errors[errorPnk].length > 0) {
                    validity = false
                }
            })

            if (validity) {
                setstate({
                    ...state, posting: true
                })

                updateCreatorHub()
            }
        }
    }

    const updateCreatorHub = async () => {
        let { input } = state
        let { errors } = state
        let { posting } = state

        try {
            let formData = new FormData()

            formData.append("page_name", input.page_name)
            formData.append("creator", input.creating)
            formData.append("profile", input.profile)
            formData.append("cover_photo", input.coverPhoto)
            formData.append("bio", input.bio)
            formData.append("youtube", input.youtube)
            formData.append("instagram", input.instagram)
            formData.append("tiktok", input.tiktok)
            formData.append("facebook", input.facebook)
            formData.append("twitter", input.twitter)
            formData.append("spotify", input.spotify)
            formData.append("apple_music", input.apple_music)

            const hubResp: any = await HttpServices.httpPostMultipartForm(ACCOUNT.CREATOR_HUB, formData)

            if (hubResp.data.success) {
                setstate({
                    ...state, status: 'pending'
                })

                let toastMessage = (
                    <span className="text-sm">
                        <span className="block">Your profile has been published</span>
                        <span className="block">Click on <span className="text-orange-500">Preview</span> to check it out.</span>
                    </span>
                )

                toast.success(toastMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                fetchCreatorSettings()
            } else {
                let payload: any = hubResp.data

                if (payload.page_name) {
                    errors.page_name = payload.page_name
                }
            }

            posting = false
        } catch (error) {
            posting = false
        }

        setstate({
            ...state, posting
        })
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Creator Hub</title>
            </Helmet>

            <div className="w-full container md:text-xl md:max-w-4xl lg:max-w-4xl xl:max-w-5xl h-full">
                <section className="w-full pb-3">
                    <h1 className="text-orange-600 text-4xl font-medium mb-2">
                        Creator Hub
                    </h1>

                    <span className="text-lg text-gray-600 font-medium block">
                        Customize your creator page
                    </span>
                </section>

                {
                    state.status === 'rejected' ? (
                        <div className="flex items-center justify-center">
                            {
                                state.httpStatus === 404 ? (
                                    <ERR_404
                                        compact={true}
                                    />
                                ) : (
                                    <ERR_500 />
                                )
                            }
                        </div>
                    ) : state.status === 'fulfilled' ? (
                        <form onSubmit={onFormSubmitHandler} className="w-full pt-4 rounded-md text-base flex flex-col md:w-2/3 md:px-0 px-2">
                            <div className="w-full pb-5 flex flex-row-reverse">
                                <Link to={API_RouteReplace(creatorsPageRoute, ':creator', state.data.entity.link)} target="_blank" className="md:w-auto w-full disabled:cursor-not-allowed text-sm rounded-md shadow-sm px-4 py-1-5 bg-gray-600 text-orange-200 hover:bg-gray-700 focus:outline-none flex align-middle justify-center items-center gap-x-3">
                                    <span className="fa-duotone fa-eye fa-2x"></span>
                                    Preview
                                </Link>
                            </div>

                            <div className="w-full pb-5">
                                <div className="w-full mb-2">
                                    <span className="text-gray-600">Name of Big Fan page</span>
                                </div>

                                <div className="w-full">
                                    <div className="relative rounded shadow-sm">
                                        <input type="text" name="page_name" id="page_name" placeholder="Page Name" autoComplete="off"
                                            className={classNames(
                                                state.errors.page_name.length > 0 ?
                                                    'text-red-900 ring-slate-300 placeholder:text-red-400 focus:ring-red-600 border border-red-600 focus:outline-red-500' :
                                                    'text-stone-900 ring-slate-300 placeholder:text-stone-400 focus:border-0 focus:outline-none focus:ring-orange-600 focus:outline-orange-500 hover:border-stone-400 border border-stone-300',
                                                'block w-full rounded-md py-2 pl-3 pr-8  text-sm'
                                            )} onChange={onChangeHandler} value={state.input.page_name} onBlur={onInputBlur} required />
                                        <div className="absolute inset-y-0 right-0 flex items-center w-8">
                                            {
                                                state.errors.page_name.length > 0 ? (
                                                    <span className="fa-duotone text-red-500 fa-circle-exclamation fa-lg"></span>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                    {
                                        state.errors.page_name.length > 0 ? (
                                            <span className='invalid-feedback text-xs text-red-600 pl-0'>
                                                {state.errors.page_name}
                                            </span>
                                        ) : null
                                    }
                                </div>
                            </div>

                            <div className="w-full pb-5">
                                <div className="w-full mb-2">
                                    <span className="text-gray-600">
                                        Who are you as a creator?
                                    </span>
                                </div>

                                <div className="w-full">
                                    <div className="relative rounded shadow-sm">
                                        <input type="text" name="creating" id="creating" placeholder="Music Artist, Comedian, Painting Artist" autoComplete="off"
                                            className={classNames(
                                                state.errors.creating.length > 0 ?
                                                    'text-red-900 ring-slate-300 placeholder:text-red-400 focus:ring-red-600 border border-red-600 focus:outline-red-500' :
                                                    'text-stone-900 ring-slate-300 placeholder:text-stone-400 focus:border-0 focus:outline-none focus:ring-orange-600 focus:outline-orange-500 hover:border-stone-400 border border-stone-300',
                                                'block w-full rounded-md py-2 pl-3 pr-8  text-sm'
                                            )} onChange={onChangeHandler} value={state.input.creating} onBlur={onInputBlur} />
                                        <div className="absolute inset-y-0 right-0 flex items-center w-8">
                                            {
                                                state.errors.creating.length > 0 ? (
                                                    <span className="fa-duotone text-red-500 fa-circle-exclamation fa-lg"></span>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                    {
                                        state.errors.creating.length > 0 ? (
                                            <span className='invalid-feedback text-xs text-red-600 pl-0'>
                                                {state.errors.creating}
                                            </span>
                                        ) : null
                                    }
                                </div>
                            </div>

                            <div className="w-full pb-5">
                                <div className="w-full mb-2">
                                    <span className="text-gray-600 block">
                                        Cover photo
                                    </span>

                                    <span className="block text-gray-500 text-sm">
                                        We recommend an image at least 1600px wide and 400px tall.
                                    </span>
                                </div>

                                <div className="w-full py-3">
                                    {
                                        state.data.entity.cover_photo ? (
                                            <div className=" h-48  bg-sky-100">
                                                <div className="h-full bg-cover bg-center" style={{ backgroundImage: `url(${process.env.REACT_APP_FQDN + '/files/covers/' + state.data.entity.cover_photo})` }}>
                                                    <div className="w-full h-full bg-black bg-opacity-50 flex justify-center">
                                                        <section className="gx-container h-full w-full flex items-center justif-center">
                                                            {
                                                                state.uploading ? (
                                                                    <>
                                                                        <span className="mx-auto block cursor-not-allowed text-gray-200 bg-gray-700 bg-opacity-70 rounded-full px-2 mb-4 py-2">
                                                                            <span className="fa-duotone fa-spinner-third fa-2x m-auto block fa-spin"></span>
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <label
                                                                        htmlFor="file-upload"
                                                                        className="relative cursor-pointer text-gray-300 hover:text-gray-200 bg-gray-700 bg-opacity-70 rounded-full mx-auto font-medium px-3 mb-4 py-2 focus:outline-none focus-within:outline-none "
                                                                    >
                                                                        <span>
                                                                            Upload Cover Photo
                                                                        </span>
                                                                        <input id="file-upload" name="coverPhoto" type="file" onChange={(e) => onFileChangeHandler(e)} className="sr-only" />
                                                                    </label>
                                                                )
                                                            }
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex flex-col md:flex-row md:space-x-4 justify-center px-6 pt-4 pb-4 border-2 border-stone-300 border-dashed rounded-md">
                                                <div className="text-center flex align-middle items-center">
                                                    <svg
                                                        className="mx-auto h-16 w-16 text-stone-400"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        viewBox="0 0 48 48"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <div className="text-sm w-full ml-3 text-stone-600">
                                                        <label
                                                            htmlFor="file-upload"
                                                            className="relative cursor-pointer rounded bg-white text-orange-600 hover:text-orange-700 focus:outline-none focus-within:outline-none "
                                                        >
                                                            <span>
                                                                Upload Cover Photo
                                                            </span>
                                                            <input id="file-upload" name="coverPhoto" type="file" onChange={(e) => onFileChangeHandler(e)} className="sr-only" />
                                                        </label>
                                                        <p className="pl-1"></p>
                                                        <p className="text-xs text-stone-500">png, jpg, jpeg up to 2MB</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        state.errors.coverPhoto.length > 0 ? (
                                            <span className='invalid-feedback text-xs text-red-600 mt-3'>
                                                {state.errors.coverPhoto}
                                            </span>
                                        ) : null
                                    }
                                </div>
                            </div>

                            <div className="w-full">
                                <div className="w-full mb-2">
                                    <span className="text-gray-600 block mb-2">
                                        About your page
                                    </span>

                                    <span className="block text-gray-500 text-sm">
                                        This is the first thing your audience will see when they land on your page, so make sure you paint a compelling picture of how they can support you on your journey.
                                    </span>
                                </div>

                                <div className="w-full py-3">
                                    <div className="relative rounded">
                                        <textarea name="bio" id="bio" placeholder="Let your fans know who you are" autoComplete="off" rows={4} cols={1}
                                            className={classNames(
                                                state.errors.bio.length > 0 ?
                                                    'text-red-900 ring-slate-300 placeholder:text-red-400 focus:ring-red-600 focus:outline-red-500 hover:border-red-400 border border-red-300' :
                                                    'text-gray-900 ring-slate-300 placeholder:text-gray-400 focus:ring-orange-600 focus:outline-orange-500 hover:border-stone-400 border border-stone-300',
                                                'block w-full rounded-md py-2 pl-3 pr-8 border border-gray-300 resize-none text-sm focus:outline-none disabled:cursor-not-allowed focus:border-0'
                                            )} onChange={onChangeHandler} disabled={state.posting} value={state.input.bio} onBlur={onInputBlur} required onFocus={() => setIsFocused(true)} />
                                        <div className="absolute inset-y-0 right-0 flex items-center w-8">
                                            {
                                                state.errors.bio.length > 0 ? (
                                                    <span className="fa-duotone text-red-500 fa-circle-exclamation fa-lg"></span>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                    <div className="w-full">
                                        <div className="w-full flex flex-col-reverse md:flex-row-reverse py-1">
                                            <span className={`text-sm ${state.wordCount > 450 && isFocused ? 'text-red-600 animate-bounce' : 'text-stone-600'} py-1-5 px-4 float-end`}>
                                                {state.wordCount}/500
                                            </span>

                                            <div className="flex-1">
                                                {
                                                    state.errors.bio.length > 0 ? (
                                                        <span className='invalid-feedback text-xs text-red-600 pl-0 float-start'>
                                                            {state.errors.bio}
                                                        </span>
                                                    ) : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full pb-5">
                                <div className="w-full mb-2">
                                    <span className="text-gray-700 text-xl block mb-2">
                                        Social Links
                                    </span>

                                    <span className="block text-gray-500 text-sm">
                                        Share external links with your audience.
                                    </span>
                                </div>
                            </div>

                            <div className="w-full pb-5 flex flex-col md:flex-row items-center align-middle">
                                <div className="w-full md:basis-1/3">
                                    <span className="text-gray-600 md:mb-0 mb-2 flex gap-x-3 align-middle items-center">
                                        <span className="fa-brands fa-youtube"></span>
                                        YouTube
                                    </span>
                                </div>

                                <div className="w-full basis-2/3">
                                    <div className="relative rounded shadow-sm">
                                        <input type="text" name="youtube" id="youtube" placeholder="https://youtube.com" autoComplete="off"
                                            className={classNames(
                                                state.errors.youtube.length > 0 ?
                                                    'text-red-900 ring-slate-300 placeholder:text-red-400 focus:ring-red-600 border border-red-600 focus:outline-red-500' :
                                                    'text-stone-900 ring-slate-300 placeholder:text-stone-400 focus:border-0 focus:outline-none focus:ring-orange-600 focus:outline-orange-500 hover:border-stone-400 border border-stone-300',
                                                'block w-full rounded-md py-2 pl-3 pr-8  text-sm'
                                            )} onChange={onChangeHandler} value={state.input.youtube} onBlur={onInputBlur} />
                                        <div className="absolute inset-y-0 right-0 flex items-center w-8">
                                            {
                                                state.errors.youtube.length > 0 ? (
                                                    <span className="fa-duotone text-red-500 fa-circle-exclamation fa-lg"></span>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                    {
                                        state.errors.youtube.length > 0 ? (
                                            <span className='invalid-feedback text-xs text-red-600 pl-0 float-start md:pb-0 pb-4'>
                                                {state.errors.youtube}
                                            </span>
                                        ) : null
                                    }
                                </div>
                            </div>

                            <div className="w-full pb-5 flex flex-col md:flex-row items-center align-middle">
                                <div className="w-full md:basis-1/3">
                                    <span className="text-gray-600 md:mb-0 mb-2 flex gap-x-3 align-middle items-center">
                                        <span className="fa-brands fa-instagram"></span>
                                        Instagram
                                    </span>
                                </div>

                                <div className="w-full basis-2/3">
                                    <div className="relative rounded shadow-sm">
                                        <input type="text" name="instagram" id="instagram" placeholder="https://instagram.com" autoComplete="off"
                                            className={classNames(
                                                state.errors.instagram.length > 0 ?
                                                    'text-red-900 ring-slate-300 placeholder:text-red-400 focus:ring-red-600 border border-red-600 focus:outline-red-500' :
                                                    'text-stone-900 ring-slate-300 placeholder:text-stone-400 focus:border-0 focus:outline-none focus:ring-orange-600 focus:outline-orange-500 hover:border-stone-400 border border-stone-300',
                                                'block w-full rounded-md py-2 pl-3 pr-8  text-sm'
                                            )} onChange={onChangeHandler} value={state.input.instagram} onBlur={onInputBlur} />
                                        <div className="absolute inset-y-0 right-0 flex items-center w-8">
                                            {
                                                state.errors.instagram.length > 0 ? (
                                                    <span className="fa-duotone text-red-500 fa-circle-exclamation fa-lg"></span>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                    {
                                        state.errors.instagram.length > 0 ? (
                                            <span className='invalid-feedback text-xs text-red-600 pl-0 float-start md:pb-0 pb-4'>
                                                {state.errors.instagram}
                                            </span>
                                        ) : null
                                    }
                                </div>
                            </div>

                            <div className="w-full pb-5 flex flex-col md:flex-row items-center align-middle">
                                <div className="w-full md:basis-1/3">
                                    <span className="text-gray-600 md:mb-0 mb-2 flex gap-x-3 align-middle items-center">
                                        <span className="fa-brands fa-tiktok"></span>
                                        Tik Tok
                                    </span>
                                </div>

                                <div className="w-full basis-2/3">
                                    <div className="relative rounded shadow-sm">
                                        <input type="text" name="tiktok" id="tiktok" placeholder="https://tiktok.com" autoComplete="off"
                                            className={classNames(
                                                state.errors.tiktok.length > 0 ?
                                                    'text-red-900 ring-slate-300 placeholder:text-red-400 focus:ring-red-600 border border-red-600 focus:outline-red-500' :
                                                    'text-stone-900 ring-slate-300 placeholder:text-stone-400 focus:border-0 focus:outline-none focus:ring-orange-600 focus:outline-orange-500 hover:border-stone-400 border border-stone-300',
                                                'block w-full rounded-md py-2 pl-3 pr-8  text-sm'
                                            )} onChange={onChangeHandler} value={state.input.tiktok} onBlur={onInputBlur} />
                                        <div className="absolute inset-y-0 right-0 flex items-center w-8">
                                            {
                                                state.errors.tiktok.length > 0 ? (
                                                    <span className="fa-duotone text-red-500 fa-circle-exclamation fa-lg"></span>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                    {
                                        state.errors.tiktok.length > 0 ? (
                                            <span className='invalid-feedback text-xs text-red-600 pl-0 float-start md:pb-0 pb-4'>
                                                {state.errors.tiktok}
                                            </span>
                                        ) : null
                                    }
                                </div>
                            </div>

                            <div className="w-full pb-5 flex flex-col md:flex-row items-center align-middle">
                                <div className="w-full md:basis-1/3">
                                    <span className="text-gray-600 md:mb-0 mb-2 flex gap-x-3 align-middle items-center">
                                        <span className="fa-brands fa-facebook"></span>
                                        Facebook
                                    </span>
                                </div>

                                <div className="w-full basis-2/3">
                                    <div className="relative rounded shadow-sm">
                                        <input type="text" name="facebook" id="facebook" placeholder="https://facebook.com" autoComplete="off"
                                            className={classNames(
                                                state.errors.facebook.length > 0 ?
                                                    'text-red-900 ring-slate-300 placeholder:text-red-400 focus:ring-red-600 border border-red-600 focus:outline-red-500' :
                                                    'text-stone-900 ring-slate-300 placeholder:text-stone-400 focus:border-0 focus:outline-none focus:ring-orange-600 focus:outline-orange-500 hover:border-stone-400 border border-stone-300',
                                                'block w-full rounded-md py-2 pl-3 pr-8  text-sm'
                                            )} onChange={onChangeHandler} value={state.input.facebook} onBlur={onInputBlur} />
                                        <div className="absolute inset-y-0 right-0 flex items-center w-8">
                                            {
                                                state.errors.facebook.length > 0 ? (
                                                    <span className="fa-duotone text-red-500 fa-circle-exclamation fa-lg"></span>
                                                ) : null
                                            }
                                        </div>

                                        {
                                            state.errors.facebook.length > 0 ? (
                                                <span className='invalid-feedback text-xs text-red-600 pl-0 float-start md:pb-0 pb-4'>
                                                    {state.errors.facebook}
                                                </span>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="w-full pb-5 flex flex-col md:flex-row items-center align-middle">
                                <div className="w-full md:basis-1/3">
                                    <span className="text-gray-600 md:mb-0 mb-2 flex gap-x-3 align-middle items-center">
                                        <img src={twitterIcon} className="w-5" />
                                        Twitter (X)
                                    </span>
                                </div>

                                <div className="w-full basis-2/3">
                                    <div className="relative rounded shadow-sm">
                                        <input type="text" name="twitter" id="twitter" placeholder="https://x.com" autoComplete="off"
                                            className={classNames(
                                                state.errors.twitter.length > 0 ?
                                                    'text-red-900 ring-slate-300 placeholder:text-red-400 focus:ring-red-600 border border-red-600 focus:outline-red-500' :
                                                    'text-stone-900 ring-slate-300 placeholder:text-stone-400 focus:border-0 focus:outline-none focus:ring-orange-600 focus:outline-orange-500 hover:border-stone-400 border border-stone-300',
                                                'block w-full rounded-md py-2 pl-3 pr-8  text-sm'
                                            )} onChange={onChangeHandler} value={state.input.twitter} onBlur={onInputBlur} />
                                        <div className="absolute inset-y-0 right-0 flex items-center w-8">
                                            {
                                                state.errors.twitter.length > 0 ? (
                                                    <span className="fa-duotone text-red-500 fa-circle-exclamation fa-lg"></span>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                    {
                                        state.errors.twitter.length > 0 ? (
                                            <span className='invalid-feedback text-xs text-red-600 pl-0 float-start md:pb-0 pb-4'>
                                                {state.errors.twitter}
                                            </span>
                                        ) : null
                                    }
                                </div>
                            </div>

                            <div className="w-full pb-5 flex flex-col md:flex-row items-center align-middle">
                                <div className="w-full md:basis-1/3">
                                    <span className="text-gray-600 md:mb-0 mb-2 flex gap-x-3 align-middle items-center">
                                        <span className="fa-brands fa-spotify"></span>
                                        Spotify
                                    </span>
                                </div>

                                <div className="w-full basis-2/3">
                                    <div className="relative rounded shadow-sm">
                                        <input type="text" name="spotify" id="spotify" placeholder="https://spotify.com" autoComplete="off"
                                            className={classNames(
                                                state.errors.spotify.length > 0 ?
                                                    'text-red-900 ring-slate-300 placeholder:text-red-400 focus:ring-red-600 border border-red-600 focus:outline-red-500' :
                                                    'text-stone-900 ring-slate-300 placeholder:text-stone-400 focus:border-0 focus:outline-none focus:ring-orange-600 focus:outline-orange-500 hover:border-stone-400 border border-stone-300',
                                                'block w-full rounded-md py-2 pl-3 pr-8  text-sm'
                                            )} onChange={onChangeHandler} value={state.input.spotify} onBlur={onInputBlur} />
                                        <div className="absolute inset-y-0 right-0 flex items-center w-8">
                                            {
                                                state.errors.spotify.length > 0 ? (
                                                    <span className="fa-duotone text-red-500 fa-circle-exclamation fa-lg"></span>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                    {
                                        state.errors.spotify.length > 0 ? (
                                            <span className='invalid-feedback text-xs text-red-600 pl-0 float-start md:pb-0 pb-4'>
                                                {state.errors.spotify}
                                            </span>
                                        ) : null
                                    }
                                </div>
                            </div>

                            <div className="w-full pb-5 flex flex-col md:flex-row items-center align-middle">
                                <div className="w-full md:basis-1/3">
                                    <span className="text-gray-600 md:mb-0 mb-2 flex gap-x-3 align-middle items-center">
                                        <span className="fa-brands fa-apple"></span>
                                        Apple Music
                                    </span>
                                </div>

                                <div className="w-full basis-2/3">
                                    <div className="relative rounded shadow-sm">
                                        <input type="text" name="apple_music" id="apple_music" placeholder="https://music.apple.com" autoComplete="off"
                                            className={classNames(
                                                state.errors.apple_music.length > 0 ?
                                                    'text-red-900 ring-slate-300 placeholder:text-red-400 focus:ring-red-600 border border-red-600 focus:outline-red-500' :
                                                    'text-stone-900 ring-slate-300 placeholder:text-stone-400 focus:border-0 focus:outline-none focus:ring-orange-600 focus:outline-orange-500 hover:border-stone-400 border border-stone-300',
                                                'block w-full rounded-md py-2 pl-3 pr-8  text-sm'
                                            )} onChange={onChangeHandler} value={state.input.apple_music} onBlur={onInputBlur} />
                                        <div className="absolute inset-y-0 right-0 flex items-center w-8">
                                            {
                                                state.errors.apple_music.length > 0 ? (
                                                    <span className="fa-duotone text-red-500 fa-circle-exclamation fa-lg"></span>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                    {
                                        state.errors.apple_music.length > 0 ? (
                                            <span className='invalid-feedback text-xs text-red-600 pl-0 float-start md:pb-0 pb-4'>
                                                {state.errors.apple_music}
                                            </span>
                                        ) : null
                                    }
                                </div>
                            </div>

                            <div className="w-full flex flex-col md:flex-row-reverse items-center align-middle pt-6 mt-3 border-t">
                                <button type="submit" className="w-auto min-w-24 justify-center disabled:cursor-not-allowed text-sm rounded-md border border-transparent shadow-sm px-4 py-1-5 bg-orange-600 text-white disabled:bg-orange-600 hover:bg-orange-700 focus:outline-none" disabled={state.posting}>
                                    {
                                        state.posting ? (
                                            <span className="flex flex-row items-center h-5 justify-center">
                                                <i className="fad fa-spinner-third fa-xl fa-spin"></i>
                                            </span>
                                        ) : (
                                            <span>Publish Changes</span>
                                        )
                                    }
                                </button>
                            </div>
                        </form>
                    ) : (
                        <div className="py-4">
                            <Loading />
                        </div>
                    )
                }
            </div>
        </React.Fragment>
    )
}