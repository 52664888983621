import React from "react"
import { Helmet } from "react-helmet"

import { Header } from "./Header"
import { Link } from "react-router-dom"
import { Footer } from "./Footer"

export const PrivacyPolicy = () => {

    return (
        <React.Fragment>
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>

            <div className="flex h-screen w-full">
                <Header />

                <div className="flex-grow w-full relative isolate">
                    <div className="bg-orange-200 md:col-span-4 pb-8 text-gray-700 pt-[85px]">
                        <div className="container mx-auto md:text-xl pt-4 md:max-w-4xl lg:max-w-4xl xl:max-w-5xl">
                            <h3 className="mt-4 mb-4 text-4xl sm:text-5xl leading-normal font-extrabold tracking-tight">
                                Privacy <span className="text-orange-600">Policy</span>
                            </h3>

                            <div className="flex items-center mt-5">
                                <span className="text-sm">
                                    Learn how we protect your data, handle your information, and respect your privacy.
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="mx-auto container md:text-xl py-4 text-base md:max-w-4xl lg:max-w-4xl xl:max-w-5xl pb-5">
                        <div className="sm:flex flex items-center">
                            <div className="prose prose-lg text-sm text-stone-600 gap-y-3 flex flex-col">
                                <div className="mx-auto">
                                    <section className="mb-8 mt-2">
                                        <h2 className="text-xl mb-4">
                                            1. Introduction
                                        </h2>

                                        <p className="mb-2">
                                            Bigfan Limited ("we", "us", "our") respects your privacy and we are committed to protecting the Personal Data we process about you. Bigfan Limited is the owner and operator of <Link className="text-blue-700" to="">www.bigfan.co.ke</Link> ("Bigfan"), a creators platform which enables: ("Creators" to get contributions from their fans/audiences.
                                        </p>

                                        <p className="mb-2">
                                            This privacy policy ("Policy") explains our practices with respect to the Personal Data processed about our Creators and Fans. It also explains our practices with respect to the Personal Data processed about individuals that feature in information uploaded by a Creator ("Collaborators"), and where we process Personal Data in the context of our business relationships.
                                        </p>

                                        <p className="mb-2">
                                            We process your Personal Data when you use Bigfan and for the provision of the services that we offer from time to time via our platform (the "Services"). We are a "data controller" of the Personal Data that we process in connection with the Services. This means that we decide the reasons why we process Personal Data about you and how we do so.
                                        </p>

                                        <p className="mb-2">
                                            If you have any questions about this Policy or our processing of your Personal Data, please contact us.
                                        </p>
                                    </section>

                                    <section className="mb-8 mt-2">
                                        <h2 className="text-xl mb-4">
                                            2. What is personal data?
                                        </h2>

                                        <p className="mb-2">
                                            This refers to any information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular person or household.
                                        </p>

                                        <p className="mb-2">
                                            In addition, we may collect data that is not capable of identifying you or is otherwise not associated or linked with you, such as deidentified, aggregated or anonymised information. This type of data is not Personal Data and our use of such data is not subject to this Policy.
                                        </p>
                                    </section>

                                    <section className="mb-8 mt-2">
                                        <h2 className="text-xl mb-4">
                                            3. Informing us of changes
                                        </h2>

                                        <p className="mb-2">
                                            It is important that the Personal Data we hold about you is accurate and current. Please keep us informed if your Personal Data changes at any point during your relationship with us. Updates or corrections can be made through your profile on Bigfan.
                                        </p>
                                    </section>

                                    <section className="mb-8 mt-2">
                                        <h2 className="text-xl mb-4">
                                            4. Applicability of this Policy (18+)
                                        </h2>

                                        <p className="mb-2">
                                            This Policy is provided in addition to, but does not form part of, our Terms of service that govern your use of Bigfan and the Services.
                                        </p>

                                        <p className="mb-2">
                                            Our Services are strictly intended for individuals 18 years of age or older. Anyone under 18 years of age is not permitted to use the Services. By using the Services, you represent that you are 18 years of age or older.
                                        </p>
                                    </section>

                                    <section className="mb-8 mt-2">
                                        <h2 className="text-xl mb-4">
                                            5. Third-party links
                                        </h2>

                                        <p className="mb-2">
                                            Bigfan may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share Personal Data about you.
                                        </p>

                                        <p className="mb-2">
                                            We are not responsible for, and this Policy does not apply to, the content, security or privacy practices of those other websites, plug-ins or applications. We encourage you to view the privacy and cookie policies / notices of those third parties to find out how your Personal Data may be used.
                                        </p>
                                    </section>

                                    <section className="mb-8 mt-2">
                                        <h2 className="text-xl mb-4">
                                            6. If you do not wish to provide Personal Data
                                        </h2>

                                        <p className="mb-2">
                                            We need to collect certain Personal Data from you to provide you with access to the Services, or specific features and functionalities of the Services, in accordance with our contract with you (i.e. our Terms of service).
                                        </p>

                                        <p className="mb-2">
                                            We are also required to process certain Personal Data in accordance with applicable laws. Please note that if you do not wish to provide Personal Data where requested, we may not be able to provide you with access to the Services or specific features and functionalities of the Services.
                                        </p>
                                    </section>

                                    <section className="mb-8 mt-2">
                                        <h2 className="text-xl mb-4">
                                            7. Updates to this Policy
                                        </h2>

                                        <p className="mb-2">
                                            We may update this Policy from time to time, and any updates will be effective upon our posting of the revised Policy on Bigfan. We will use reasonable efforts to notify you in the event that material updates are made to this Policy, such as sending you a feed notification or an email via your account.
                                        </p>
                                    </section>

                                    <section className="mb-8 mt-2">
                                        <h2 className="text-xl mb-4">
                                            8. Categories of Personal Data
                                        </h2>

                                        <p className="mb-2">
                                            We process, or our third-party providers process on our behalf, different kinds of Personal Data about Creators, Content Collaborators and Fans, are as follows:
                                        </p>

                                        <p className="mb-2 underline">
                                            For Creators:
                                        </p>

                                        <p className="mb-2 pl-6">
                                            (i) Official Names as per identity document (National ID/Passport)
                                        </p>

                                        <p className="mb-2 pl-6">
                                            (ii) Alias/stage name, your creator name
                                        </p>

                                        <p className="mb-2 pl-6">
                                            (iii) Telephone Number
                                        </p>

                                        <p className="mb-2 pl-6">
                                            (iv) Nationality
                                        </p>

                                        <p className="mb-2 pl-6">
                                            (v) E-mail address
                                        </p>

                                        <p className="mb-2 pl-6">
                                            (vi) Digital copy of National identity card / Passport
                                        </p>

                                        <p className="mb-2 pl-6">
                                            (vii) Creator Earnings
                                        </p>

                                        <p className="mb-2 pl-6">
                                            (viii) Payments made to you from your fans/audience
                                        </p>

                                        <p className="mb-2 pl-6">
                                            (viii) Funds withdrawal
                                        </p>

                                        <p className="mb-2 underline">
                                            For Fans:
                                        </p>

                                        <p className="mb-2 pl-6">
                                            (i) Telephone number
                                        </p>

                                        <p className="mb-2 pl-6">
                                            (ii) Payments made to a creator
                                        </p>
                                    </section>

                                    <section className="mb-8 mt-2">
                                        <h2 className="text-xl mb-4">
                                            9. Onboarding processes
                                        </h2>

                                        <p className="mb-2">
                                            We have processes in place that are intended to ensure that Creators on Bigfan: (i) are at least 18 years of age; and (ii) verify their identity. Before you can start a Creator account, we will:
                                        </p>

                                        <p className="mb-2 pl-6">
                                            (i) Ask you to provide Creator User Data, as set out at Section 8 above
                                        </p>

                                        <p className="mb-2 pl-6">
                                            (ii) Check your country of residence. This check is intended to ensure lawful access to Bigfan and the Services.
                                        </p>

                                        <p className="mb-2 pl-6">
                                            (iii) Ask you to provide Financial Data, as set out at Section 8. This is necessary so that payments can be made to Creators for content, and so that Creators can access their earnings via Bigfan. Financial Data is also collected as a verification and anti-fraud measure.
                                        </p>

                                        <p className="mb-2 pl-6">
                                            (iv) Ask you to go through a third-party age and identity verification process, as described below.
                                        </p>

                                        <p className="mb-2 pl-6">
                                            (v) Check that you have not previously been banned from using Bigfan and our Services (e.g. for violating our terms of service)
                                        </p>
                                    </section>

                                    <section className="mb-8 mt-2">
                                        <h2 className="text-xl mb-4">
                                            10. Lawful bases for processing
                                        </h2>

                                        <p className="mb-2">
                                            <span className="font-bold">Performance of a contract:</span> By using the Services, you have contracted with us through our Terms of Service, and we will process Personal Data to perform that contract (e.g. to fulfill transactions between Fans and Creators and process Creator earnings) and to enforce the terms of that contract.

                                            This Policy is provided in addition to, but does not form part of, our Terms of service that govern your use of Bigfan and the Services.
                                        </p>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}