import React, { useState } from "react"
import { Helmet } from "react-helmet"

import { Header } from "./Header"
import { Footer } from "./Footer"
import QRpana from "../../assets/images/QR_Code_pana.svg"
import scanQR from "../../assets/images/B5NqjNJMbOBL08O1A.svg"
import amount from "../../assets/images/CjvPVsM4P9gl66J9A.svg"
import sendMessage from "../../assets/images/5X2sN118N4oSRytxF.svg"
import paymentMethod from "../../assets/images/9KVyGIRJtaUcEo5jw.svg"
import Prototype from "../../assets/images/Prototyping_process_pana.svg"
import profileData from "../../assets/images/Profile_data_rafiki.svg"
import detailsReview from "../../assets/images/Customer_Survey_rafiki.svg"

export const HowItWorks = () => {
    const [activeTab, setActiveTab] = useState('creators');

    return (
        <React.Fragment>
            <Helmet>
                <title>How It Works</title>
            </Helmet>

            <div className="flex h-screen w-full">
                <Header />

                <div className="flex-grow w-full relative isolate">
                    <div className="bg-orange-200 md:col-span-4 pb-8 text-gray-700 pt-[85px]">
                        <div className="container mx-auto md:text-xl pt-4 md:max-w-4xl lg:max-w-4xl xl:max-w-5xl">
                            <h3 className="mt-4 mb-4 text-4xl sm:text-5xl leading-normal font-extrabold tracking-wide">
                                How it <span className="text-orange-600">Works</span>
                            </h3>

                            <p className="my-4 leading-7 text-gray-700 md:text-lg text-base">
                                Learn how our platform works, guiding you through each step - from discovering creators to supporting them directly with your contribution
                            </p>
                        </div>
                    </div>

                    <div className="w-full mx-auto max-w-md p-4 px-6 md:py-4 py-6">
                        <div className="relative flex justify-between text-center text-base md:text-lg font-semibold md:border-b-2 border-b pb-4 md:pb-2 border-gray-300">
                            <div className={`absolute top-0 h-11 w-1/2 rounded-lg shadow-md bg-orange-500 transition-transform duration-300 ease-in-out ${activeTab === 'fans' ? 'translate-x-full' : ''}`}
                            ></div>

                            <button onClick={() => setActiveTab('creators')} className={`w-1/2 py-2 bg-inherit z-10 ${activeTab === 'creators' ? 'text-white' : ''}`}>
                                For Creators
                            </button>

                            <button onClick={() => setActiveTab('fans')} className={`w-1/2 py-2 bg-inherit z-10 ${activeTab === 'fans' ? 'text-white' : ''}`}>
                                For Fans
                            </button>
                        </div>
                    </div>

                    <div className="mt-2 max-w-screen-md mx-auto px-6">
                        {activeTab === 'creators' ? (
                            <div className="flex flex-col gap-y-4">
                                <span className="mb-1 block">
                                    Create. Connect. Thrive!
                                </span>

                                <span className="text-gray-500 text-base">
                                    Let your fans fuel your journey.
                                </span>

                                <div className="flex flex-col md:flex-row items-center align-middle py-3">
                                    <div className="md:basis-2/3">
                                        <h1 className="text-base font-medium tracking-tight sm:text-lg">
                                            1. Create an Account

                                            <div className="w-48 pt-4 mx-auto pb-3 md:hidden">
                                                <img src={Prototype} alt={"prototype_process"} width="auto" className="block text-center m-auto" />
                                            </div>
                                        </h1>

                                        <p className="mt-3 md:text-base text-base leading-8 text-gray-600 pb-3 md:py-0">
                                            New to <span className="text-orange-600">Bigfan</span>, click on the "Get Started" button. Sign up with your Google account or an email/password
                                        </p>
                                    </div>

                                    <div className="md:basis-1/3 md:block h-full px-4">
                                        <img className="h-full rounded-2xl" src={Prototype} alt={"prototype_process"} loading="lazy" />
                                    </div>
                                </div>

                                <div className="flex flex-col md:flex-row-reverse items-center align-middle py-3">
                                    <div className="md:basis-2/3">
                                        <h1 className="text-base font-medium tracking-tight sm:text-lg">
                                            2. Add Account Details

                                            <div className="w-48 pt-4 mx-auto pb-3 md:hidden">
                                                <img src={profileData} alt={"profile_data"} width="auto" className="block text-center m-auto" />
                                            </div>
                                        </h1>

                                        <p className="mt-6 md:text-base text-base leading-8 text-gray-600 pb-3 md:py-0">
                                            Include your phone number, creator name and a short bio for your fans.
                                        </p>
                                    </div>

                                    <div className="md:basis-1/3 md:block h-full px-4">
                                        <img className="h-full rounded-2xl" src={profileData} alt={"profile_data"} loading="lazy" />
                                    </div>
                                </div>

                                <div className="flex flex-col md:flex-row items-center align-middle py-3">
                                    <div className="md:basis-2/3">
                                        <h1 className="text-base font-medium tracking-tight sm:text-lg">
                                            3. Submit & Await Verification

                                            <div className="w-48 pt-4 mx-auto pb-3 md:hidden">
                                                <img src={detailsReview} alt={"review_details"} width="auto" className="block text-center m-auto" />
                                            </div>
                                        </h1>

                                        <p className="mt-6 md:text-base text-base leading-8 text-gray-600 pb-3 md:py-0">
                                            Submit your details your account will be verified. You'll receive a notification via email
                                        </p>
                                    </div>

                                    <div className="md:basis-1/3 md:block h-full px-4">
                                        <img className="h-full rounded-2xl" src={detailsReview} alt={"review_details"} loading="lazy" />
                                    </div>
                                </div>

                                <div className="flex flex-col md:flex-row-reverse items-center align-middle py-3">
                                    <div className="md:basis-2/3">
                                        <h1 className="text-base font-medium tracking-tight sm:text-lg">
                                            4. Download Your Creator QR Code

                                            <div className="w-48 pt-4 mx-auto pb-3 md:hidden">
                                                <img src={QRpana} alt={"download_qr_code"} width="auto" className="block text-center m-auto" />
                                            </div>
                                        </h1>

                                        <p className="mt-6 md:text-base text-base leading-8 text-gray-600 pb-3 md:py-0">
                                            Share your unique QR code with your fans. Encourage fans to scan the QR code and contribute during your content or live performances.
                                        </p>
                                    </div>

                                    <div className="md:basis-1/3 md:block h-full px-4">
                                        <img className="h-full rounded-2xl" src={QRpana} alt={"download_qr_code"} loading="lazy" />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col gap-y-4">
                                <span className="md:mb-1 mb-2 block">
                                    Because Your Favorite Creators Deserve It!
                                </span>

                                <span className="text-gray-500 text-base block mb-4">
                                    Celebrate your favorite creators!
                                </span>

                                <div className="flex flex-col md:flex-row items-center align-middle py-3">
                                    <div className="md:basis-2/3">
                                        <h1 className="text-base font-medium tracking-tight sm:text-lg">
                                            1. Scan QR Code or Search Creator by Name

                                            <div className="w-48 pt-4 mx-auto pb-3 md:hidden">
                                                <img src={scanQR} alt={"scan_qr_code"} width="auto" className="block text-center m-auto" />
                                            </div>
                                        </h1>

                                        <p className="mt-6 md:text-base text-base leading-8 text-gray-600 pb-3 md:py-0">
                                            Quickly find the creator you're supporting by either scanning their personalized QR code or searching by their name.
                                        </p>
                                    </div>

                                    <div className="md:basis-1/3 md:block h-full px-4">
                                        <img className="h-full rounded-2xl" src={scanQR} alt={"scan_qr_code"} loading="lazy" />
                                    </div>
                                </div>

                                <div className="flex flex-col md:flex-row-reverse items-center align-middle py-3">
                                    <div className="md:basis-2/3">
                                        <h1 className="text-base font-medium tracking-tight sm:text-lg">
                                            2. Set Amount

                                            <div className="w-48 pt-4 mx-auto pb-3 md:hidden">
                                                <img src={amount} alt={"set_amount"} width="auto" className="block text-center m-auto" />
                                            </div>
                                        </h1>

                                        <p className="mt-6 md:text-base text-base leading-8 text-gray-600 pb-3 md:py-0">
                                            Choose how much you'd like to give—whether it's a small token or a big gesture, it all makes a difference.
                                        </p>
                                    </div>

                                    <div className="md:basis-1/3 md:block h-full px-4">
                                        <img className="h-full rounded-2xl" src={amount} alt={"set_amount"} loading="lazy" />
                                    </div>
                                </div>

                                <div className="flex flex-col md:flex-row items-center align-middle py-3">
                                    <div className="md:basis-2/3">
                                        <h1 className="text-base font-medium tracking-tight sm:text-lg">
                                            3. Enter your Phone number

                                            <div className="w-48 pt-4 mx-auto pb-3 md:hidden">
                                                <img src={paymentMethod} alt={"payment_method"} width="auto" className="block text-center m-auto" />
                                            </div>
                                        </h1>

                                        <p className="mt-6 md:text-base text-base leading-8 text-gray-600 pb-3 md:py-0">
                                            Our seamless payment system currently supports Mpesa. Input the mobile number making the transaction and await the prompt on your Mpesa then enter the pin. When inputting your phone number use the format 2547********
                                        </p>
                                    </div>

                                    <div className="md:basis-1/3 md:block h-full px-4">
                                        <img className="h-full rounded-2xl" src={paymentMethod} alt={"payment_method"} loading="lazy" />
                                    </div>
                                </div>

                                <div className="flex flex-col md:flex-row-reverse items-center align-middle py-3">
                                    <div className="md:basis-2/3">
                                        <h1 className="text-base font-medium tracking-tight sm:text-lg">
                                            4. Send a Personalized Message to the Creator(s)

                                            <div className="w-48 pt-4 mx-auto pb-3 md:hidden">
                                                <img src={sendMessage} alt={"send_message"} width="auto" className="block text-center m-auto" />
                                            </div>
                                        </h1>

                                        <p className="mt-6 md:text-base text-base leading-8 text-gray-600 pb-3 md:py-0">
                                            Add a personal touch by writing a message of support or encouragement to let the creator know how much you appreciate their workv
                                        </p>
                                    </div>

                                    <div className="md:basis-1/3 md:block h-full px-4">
                                        <img className="h-full rounded-2xl" src={sendMessage} alt={"send_message"} loading="lazy" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}