import { Helmet } from "react-helmet"
import React, { Fragment, useEffect } from "react"
import { Transition } from "@headlessui/react";

export const Start = () => {
    let currentIndex = 0;
    const carouselInterval = 5000;

    useEffect(() => {
        const interval = setInterval(() => {
            scrollCarousel(1)
        }, carouselInterval);

        return () => clearInterval(interval);
    }, [])

    function scrollCarousel(direction: any) {
        const carousel = document.getElementById("carousel");
        const totalSlides = carousel.children.length;
        currentIndex =
            (currentIndex + direction + totalSlides) % totalSlides;
        carousel.style.transform = `translateX(-${currentIndex * 100
            }%)`;
    }

    return (
        <React.Fragment>
            <Helmet>
                <script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v1.9.5/dist/alpine.js"></script>
            </Helmet>

            <main className="md:h-screen min-h-screen flex flex-col md:flex-row items-center align-middle">
                <div className="relative w-full max-w-auto mx-auto h-full">
                    <div className="overflow-hidden relative rounded-md h-full">
                        <div className="flex transition-transform duration-500 h-full ease-in-out transform" id="carousel">
                            <div className="min-w-full h-full relative">
                                <div className="flex -z-10 min-h-screen justify-center slide-1-image slide-layout w-full overflow-auto h-screen absolute object-cover top-0 left-0">
                                    <div className="bg-black opacity-30 w-full"></div>
                                </div>

                                <div className="w-full md:text-6xl text-4xl pt-24 h-full mx-auto flex flex-col justify-center items-center container md:max-w-4xl lg:max-w-4xl xl:max-w-5xl">
                                    <div className="flex flex-row align-middle items-center w-full">
                                        <div className="w-full md:w-6/12 md:text-right">
                                            <span className="text-white flex md:flex-row-reverse">
                                                <h1 className="title w-auto flex-none rounded-l-md px-2">
                                                    Live the Art
                                                    <div className="aurora">
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                    </div>
                                                </h1>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row-reverse align-middle items-center w-full">
                                        <div className="w-full md:w-7/12">
                                            <span className="text-white flex flex-row">
                                                <h1 className="title w-auto flex-none px-2 rounded-r-md">
                                                    and Feel the Love
                                                    <div className="aurora">
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                    </div>
                                                </h1>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="min-w-full h-full relative">
                                <div className="flex -z-10 min-h-screen justify-center slide-2-image slide-layout w-full overflow-auto h-screen absolute object-cover top-0 left-0">
                                    <div className="bg-black opacity-30 w-full"></div>
                                </div>

                                <div className="w-full md:text-6xl text-4xl pt-24 h-full mx-auto flex flex-col justify-center items-center container md:max-w-4xl lg:max-w-4xl xl:max-w-5xl">
                                    <div className="flex flex-row align-middle items-center w-full">
                                        <div className="w-full md:w-6/12 md:text-right">
                                            <span className="text-white flex md:flex-row-reverse">
                                                <h1 className="title w-auto flex-none rounded-l-md px-2d">
                                                    Elevated Acts
                                                    <div className="aurora">
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                    </div>
                                                </h1>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row-reverse align-middle items-center w-full">
                                        <div className="w-full md:w-7/12">
                                            <span className="text-white flex flex-row">
                                                <h1 className="title w-auto flex-none rounded-r-md px-2">
                                                    of Appreciation
                                                    <div className="aurora">
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                    </div>
                                                </h1>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="min-w-full h-full relative">
                                <div className="flex -z-10 min-h-screen justify-center slide-3-image slide-layout w-full overflow-auto h-screen absolute object-cover top-0 left-0">
                                    <div className="bg-black opacity-30 w-full"></div>
                                </div>

                                <div className="w-full md:text-6xl text-4xl pt-24 h-full mx-auto flex flex-col justify-center items-center container md:max-w-4xl lg:max-w-4xl xl:max-w-5xl">
                                    <div className="flex flex-row align-middle items-center w-full">
                                        <div className="w-full md:w-6/12 md:text-right">
                                            <span className="text-white flex md:flex-row-reverse">
                                                <h1 className="title w-auto flex-none rounded-l-md px-2">
                                                    Your stage,
                                                    <div className="aurora">
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                    </div>
                                                </h1>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="flex flex-row-reverse align-middle items-center w-full">
                                        <div className="w-full md:w-7/12">
                                            <span className="text-white flex flex-row">
                                                <h1 className="title w-auto flex-none rounded-r-md px-2">
                                                    Their Support
                                                    <div className="aurora">
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                        <div className="aurora__item"></div>
                                                    </div>
                                                </h1>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button className="absolute md:top-1/2 top-3/4 left-0 ml-3 transform -translate-y-1/2 p-3 bg-gray-400 bg-opacity-50 rounded-full text-white hover:bg-opacity-75 focus:outline-none" onClick={() => scrollCarousel(-1)}>
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                        </svg>
                    </button>

                    <button className="absolute md:top-1/2 top-3/4 right-0 mr-3 transform -translate-y-1/2 p-3 bg-gray-400 bg-opacity-50 rounded-full text-white hover:bg-opacity-75 focus:outline-none" onClick={() => scrollCarousel(1)}>
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                        </svg>
                    </button>
                </div>
            </main>
        </React.Fragment>
    )
}