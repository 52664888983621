import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"

import { Footer } from "../ingress/Footer"
import { Header } from "../ingress/Header"

export const TermsAndConditions = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>
                    Terms and Conditions
                </title>
            </Helmet>

            <div className="flex h-screen w-full">
                <Header />

                <div className="flex flex-col w-full relative isolate">
                    <div className="flex-grow w-full relative isolate">
                        <div className="bg-orange-200 md:col-span-4 pb-8 text-gray-700 pt-[85px]">
                            <div className="container mx-auto md:text-xl pt-4 md:max-w-4xl lg:max-w-4xl xl:max-w-5xl">
                                <h3 className="mt-4 mb-4 text-4xl sm:text-5xl leading-normal font-extrabold tracking-tight">
                                    Terms And <span className="text-orange-600">Conditions</span>
                                </h3>

                                <div className="flex items-center mt-5">
                                    <span className="text-sm">
                                        Our guidelines for a safe, fair, and enjoyable experience that protect both you and our platform
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex-grow">
                            <div className="w-full px-3 py-5">
                                <div className="mx-auto container md:text-xl text-base md:max-w-4xl lg:max-w-4xl xl:max-w-5xl pb-5">
                                    <div className="sm:flex flex items-center">
                                        <div className="prose prose-lg text-sm text-stone-600 gap-y-3 flex flex-col">
                                            <div className="mx-auto">
                                                <section className="mb-8">
                                                    <h2 className="text-xl mb-4">
                                                        1. Introduction
                                                    </h2>

                                                    <p className="mb-2">
                                                        These Terms of Use for all Users govern your use of BigFan and your agreement with us.
                                                    </p>

                                                    <p className="mb-2">
                                                        - We can modify these Terms of Service at any time.
                                                    </p>

                                                    <p className="mb-2">
                                                        - Your rights may vary depending on where you are resident when you access BigFan.
                                                    </p>

                                                    <p className="mb-2">
                                                        - If a dispute arises between you and us, you agree to notify us and agree to mediation before bringing any claim against us.
                                                    </p>
                                                </section>

                                                <section className="mb-8">
                                                    <h2 className="text-xl mb-4">
                                                        2. Interpretation
                                                    </h2>

                                                    <p className="mb-2">
                                                        In the Terms of Service: <br />

                                                        We refer to our website as "BigFan" or "Platform" including when accessed via the URL <Link to={'https://www.bigfan.co.ke'}></Link> references to "we", "our", "us" are references to Bigfan Limited, the operator of the BigFan website;
                                                    </p>

                                                    <p className="mb-2">
                                                        <span className="underline">"Content"</span> means any material uploaded to BigFan by any User (whether a Creative or a Fan), including any photos, videos, audio (for example music and other sounds), livestream material, data, text (such as comments and hashtags), metadata, images, interactive features, emojis, GIFs, memes, and any other material whatsoever;
                                                    </p>

                                                    <p className="mb-2">
                                                        <span className="underline">"Creative or Creator"</span> means a User who has set up their BigFan account as a Creative account to be viewed by other Users;
                                                    </p>

                                                    <p className="mb-2">
                                                        <span className="underline">"Fan"</span> means a User who wants to appreciate a Creative/Creator by sending contributions by monetary form and messages;
                                                    </p>

                                                    <p className="mb-2">
                                                        <span className="underline">"Fan/Creative Transaction or Fan/creator Transaction"</span> means any transaction between a Fan and a Creative on BigFan by which access is granted in any of the following ways:
                                                    </p>

                                                    <p className="mb-2">
                                                        (i) Contributions/payments made by a Fan to the Creative and
                                                    </p>

                                                    <p className="mb-2">
                                                        (ii) Use by the Fan of the 'fan interaction' function on a Creative's account; e.g sending personal messages
                                                    </p>

                                                    <p className="mb-2">
                                                        <span className="underline">"Fan tip or contribution or appreciation or payment"</span> means any and all payments made by a Fan to a Creative (i) in connection with a Fan/Creative Transaction, or (ii) by way of a tip for a Creative;
                                                    </p>

                                                    <p className="mb-2">
                                                        <span className="underline">"Referring User"</span> means a User who participates in the BigFan Program;
                                                        "Standard Contract between Fan and Creative" means the terms which govern each Fan/Creative Transaction, which can be found here;
                                                    </p>

                                                    <p className="mb-2">
                                                        <span className="underline">"Terms of Service"</span> (also called "your agreement with us") means the legally binding agreement between you and us which consists of:
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (i) these Terms of Use for all Users,
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (iii) Terms of Use for Creatives/Creators,
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (iv) Privacy Policy,
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (v) Acceptable Use Policy,
                                                    </p>
                                                </section>

                                                <section className="mb-8">
                                                    <h2 className="text-xl mb-4">
                                                        3. We may change, suspend, or deactivate Bigfan:
                                                    </h2>

                                                    <p className="mb-2">
                                                        We may change Bigfan from time to time for any reason. We do not guarantee that Bigfan, or its Content, will always be available or accessible without interruption. We may suspend, withdraw, or restrict the availability of any part of Bigfan
                                                    </p>
                                                </section>

                                                <section className="mb-8">
                                                    <h2 className="text-xl mb-4">
                                                        4. Registering with Bigfan:
                                                    </h2>

                                                    <p className="mb-2">
                                                        By registering with and using Bigfan, you agree to the Terms of Service. If you do not agree, your sole remedy is to not register or stop being a Bigfan User. We may reject an account application for any reason. To use Bigfan you must register and open an account on Bigfan.
                                                    </p>

                                                    <p className="mb-2">
                                                        You must provide a valid email address, a username, and a password or authenticate using an approved third-party. Your password must comply with the Bigfan password requirements.
                                                    </p>

                                                    <p className="mb-2">
                                                        To open a Creator account, you must:
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (i) Upload a valid ID
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (ii) Be an active Content Creator, Dj, Musician, event Organizer or be in the Performance Arts OR fit in the categories outlined on the sign up form.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (iii) Add a phone number or bank account, or a payment method;
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (iv) Submit additional age or identity verification information any time we ask for it;
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (v) Wait for account verification from Bigfan;
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (vi) Provide any additional information we request, which may vary depending on where you live or your nationality.
                                                    </p>

                                                    <p className="mb-2">
                                                        To be a User and contribute to any creator account:
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (i) You must be at least 18 years old;
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (ii) You must be legally permitted to join, view Content, and to use any functionality provided by Bigfan;
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (iii) You agree to pay (where required) for Creator Interactions in accordance with the Terms of Service.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (iv) You agree to pay (where required) for Creator Interactions in accordance with the Terms of Service.
                                                    </p>
                                                </section>

                                                <section className="mb-8">
                                                    <h2 className="text-xl mb-4">
                                                        5. Adult material
                                                    </h2>

                                                    <p className="mb-2">
                                                        <span className="font-semibold">
                                                            WE DO NOT ALLOW ADULT MATERIAL (PORNOGRAPHY) CONTENT CREATORS
                                                        </span>, and you acknowledge and agree to this when you access Bigfan. We are not responsible for any loss or damage you suffer as a result of breaching this guideline.
                                                    </p>
                                                </section>

                                                <section className="mb-8">
                                                    <h2 className="text-xl mb-4">
                                                        6. Fans and users Conduct
                                                    </h2>

                                                    <p className="mb-2">
                                                        You agree to use the Platform in a respectful manner. You agree NOT to:
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (i) Impersonate a Creator or Entity or Person.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (ii) Violate any applicable laws or regulations.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (iii) Infringe upon the rights of others, including intellectual property rights
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (iv) Use the Platform for any illegal or unauthorized purpose.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (v) Transmit harmful content, including viruses or malware.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (vi) Interfere with the operation of the Platform.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (vii) Harass or abuse other users through fan messages sent.
                                                    </p>

                                                    <p className="mb-2">
                                                        You are legally responsible for all Content & Information you Upload. If you are a Creator and someone else assists you with the operation of your Creator account, this does not affect your legal responsibility. Our relationship is with you, and not with any third-party, and you will be legally responsible for ensuring that all information posted and all use of your account complies with our Terms of Service.
                                                    </p>
                                                </section>

                                                <section className="mb-8">
                                                    <h2 className="text-xl mb-4">
                                                        7. Creator payouts
                                                    </h2>

                                                    <p className="mb-2 pl-6">
                                                        (a) All Fan Payments will be received and processed by a third-party payment provider we approve.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (b) Our Fee is calculated as 16% of the total Fan contributions and will be deducted from each contribution
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (c) Creator Earnings will only be available for withdrawal when reflected in your BigFan account.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (d) To withdraw your Creator Earnings from an account, your account balance must meet the minimum payout amount requirement
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (e) All Fan contributions and Creator Earnings are transacted in Ksh.
                                                    </p>
                                                </section>

                                                <section className="mb-8">
                                                    <h2 className="text-xl mb-4">
                                                        8. Fan contributions
                                                    </h2>

                                                    <p className="mb-2 pl-6">
                                                        (a) You agree not to make requests for a refund; or chargeback requests of your payment/contribution in relation to any Creator Interaction or tip to a Creator.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (b) The use of this platform is out of free will and you agree that the decision you the user make is sane and sober to support the creator
                                                    </p>
                                                </section>

                                                <section className="mb-8">
                                                    <h2 className="text-xl mb-4">
                                                        9. Our rights and obligations
                                                    </h2>

                                                    <p className="mb-2 pl-6">
                                                        (a) We reserve the right to suspend or remove a Creator account that may breach our Terms of Service.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (b) We comply with applicable laws requiring us to use proportionate measures to quickly remove illegal Content when we become aware.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (c) When we review Content in accordance with our internal policies, we reserve the right to conduct these reviews with technology tools such as classifiers, and Artificial Intelligence and Machine Learning-enabled tools to detect and remove Content that violates our policies.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (d) We may use recommender systems by, for example, displaying Creator accounts on your home page that other Users who share preferences.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (e) We are not responsible for any loss you claim to have suffered because of any action we take, in accordance with our Terms of Service, to suspend or remove your account.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (f) We may suspend or delete Content and accounts according to our Terms of Service. If we do, we will try to Notify you.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (g) If your account is terminated, we may deal with your Content in accordance with our privacy policy. You will not be able to access your account after it is terminated.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (h) We may review any suspected or alleged misuse, abuse, or unlawful use of Bigfan and cooperate with law enforcement agencies in such reviews.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (i) We may disclose any information or records in our possession or control about your use of BigFan to third parties in keeping with our Privacy Policy.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (j) We may change which third-party payment providers process Fan Contributions/Payments.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (k) Other than Content which is owned by or licensed to Creators, we and/or our licensors own all rights in and to BigFan and its entire contents.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (l) We are the sole owners of any and all anonymised data relating to your use of BigFan and we can use that data for any purpose permitted by law.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (m) We may choose how we communicate with you, including by emails and messages posted to your Bigfan account.
                                                    </p>
                                                </section>

                                                <section className="mb-8">
                                                    <h2 className="text-xl mb-4">
                                                        10. Indemnity
                                                    </h2>

                                                    <p className="mb-2">
                                                        We will exercise reasonable care and skill in delivering our website/platform to you; however, there are certain areas for which we cannot and we will not accept responsibility, including:
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (a) We do not control Content that you post. We do not choose, endorse, authorize, approve or guarantee the completeness, legitimacy, legality, accuracy, or reliability of any Content Users post. We comply with applicable laws requiring us to use proportionate measures to quickly remove accounts taking part in illegal activities when we become aware. You may report Creators using our official contacts.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (b) We do not grant you any rights in relation to Content.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (c) Any suggestions, comments, reviews, or requests that any fan provides.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (d) Any promise or guarantee that Creators or Referring Users will generate earnings from Big Fan.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (e) Whether our platform is compatible with all devices and operating systems. You are responsible for configuring your information technology, device, and computer programs to access Big Fan.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (f) The availability of the internet, or any issues in your hardware, or software, that may impact your use of or access to Bigfan.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (g) Any lost, stolen, or compromised User accounts, passwords, email accounts, or any resulting unauthorized activities or resulting unauthorized payments or withdrawals of funds.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (h) Your statutory rights: These provisions do not affect your statutory rights.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (i) Your statutory rights: These provisions do not affect your statutory rights.
                                                    </p>

                                                    <p className="mb-2 pl-6">
                                                        (j) Any damages, losses, or expenses arising out of or in connection with your use of the platform/website, including but not limited to any errors, omissions, or inaccuracies in Transactions.
                                                    </p>
                                                </section>

                                                <section className="mb-8">
                                                    <h2 className="text-xl mb-4">
                                                        11. Governing Law
                                                    </h2>

                                                    <p className="text-stone-700 mb-2">
                                                        These Terms shall be governed by and construed in accordance with the laws of the Republic of Kenya. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of the Republic of Kenya.
                                                    </p>
                                                </section>

                                                <section>
                                                    <h2 className="text-xl mb-4">
                                                        12. Amendments
                                                    </h2>

                                                    <p className="text-stone-700 mb-2">
                                                        Big Fan reserves the right to amend or modify these Terms at any time, with or without notice. Any changes to these Terms will be effective immediately upon posting. Your continued use of the platform/website following the posting of any changes constitutes acceptance of those changes.
                                                    </p>
                                                </section>

                                                <section className="mt-8">
                                                    <p className="text-stone-700 mb-2">
                                                        If you have any questions or concerns about these Terms, please contact us at <span className="text-orange-500">support@bigfan.co.ke</span>
                                                    </p>
                                                </section>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}